<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="success" size="small" @click="showSyncCpttDataDialog" icon="el-icon-refresh">同步</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'cptt_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getCptts"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog title="同步公司信息" width="70%" top="0" :visible.sync="syncCpttDialogVisible" @opened="getSyncCpttData">
        <search-table
          v-if="syncCpttDialogVisible"
          ref="syncCpttTable"
          :tableRowKey="`autoInc`"
          :data="syncCpttTableData"
          :totalPage="syncCpttTotalPage"
          :columns="syncCpttTableProperties"
          v-loading="syncLoadFlag"
          @row-dblclick="syncCpttData"
          @getTableData="getSyncCpttData"
          :need-fixed-height="true"
          :need-pagination="false"
        />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import selectStatus from '@/views/component/selectStatus';
import { syncCpttTableProperties, tableProperties } from '@/views/SystemManagement/CpttManage/cptt';
import SearchTable from '@/components/table/SearchTableV2.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CpttList',
  components: {
    SearchTable,
    selectStatus
  },
  data() {
    return {
      tableProperties: tableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      syncCpttDialogVisible: false,
      syncCpttTableData: [],
      syncCpttTotalPage: 0,
      syncCpttTableProperties: syncCpttTableProperties,
      syncLoadFlag: true
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/cptt_add' || from.path === '/cptt_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getCptts();
    },
    showSyncCpttDataDialog() {
      this.syncCpttDialogVisible = true;
    },
    syncCpttData(row) {
      this.syncCpttDialogVisible = false;
      this.$router.push({
        name: 'CpttAdd',
        query: { perm_id: this.$route.query.perm_id, autoInc: row.rich_erp_id },
        params: { syncCpttData: row }
      });
    },
    async getSyncCpttData() {
      await (this.syncLoadFlag = true);
      await cpttAPI.getRichErpCptt(this.$refs.syncCpttTable.searchForm).then(({ data }) => {
        this.syncCpttTableData = data.list;
        this.syncCpttTotalPage = data.total;
        this.syncLoadFlag = false;
      });
    },
    getCptts() {
      getNoCatch(cpttAPI.getCptts, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getCptts();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/cptt_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.cptt_id);
      });
      post(cpttAPI.deletCpttByIds, { cptt_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/cptt_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.cptt_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
