import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '公司编号', prop: 'cptt_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '公司名称（中）',
    prop: 'cptt_name',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    overflowTooltip: true
  },
  {
    label: '公司名称（英）',
    prop: 'cptt_name_en',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    overflowTooltip: true
  },
  {
    label: '地址（中）',
    prop: 'cptt_address',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    overflowTooltip: true
  },
  {
    label: '地址（英）',
    prop: 'cptt_address_en',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    overflowTooltip: true
  },
  { label: '电话', prop: 'cptt_phone', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '传真', prop: 'cptt_fax', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '税号', prop: 'cptt_tax_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: 140,
    input: true,
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const syncCpttTableProperties = [
  store.state.index,
  { label: '公司编号', prop: 'cptt_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '公司名称(中)', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司名称(英)', prop: 'cptt_name_en', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
